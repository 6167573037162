(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

/**
 * @member {string} window.myparcel_delivery_options_url
 * @member {Object} window.prestashop
 * @member {jQuery} $
 */
(function () {
  /**
   * Whether the listeners have been initialized.
   *
   * @type {boolean}
   */
  var initialized = false;
  /**
   * @type {{}}
   */

  var deliveryOptionsConfigStore = {};
  /**
   * @param {jQuery} $wrapper
   */

  function createOrMoveDeliveryOptionsForm($wrapper) {
    var $form = getDeliveryOptionsFormHandle();

    if ($form) {
      // Move the form to the new delivery option
      $form.hide();
      $wrapper.append($form);
    } else {
      // Or create the form container if it doesn't exist yet
      $wrapper.html("\n        <div id=\"myparcel-form-handle\">\n          <div class=\"loader\"></div>\n          <div id=\"myparcel-delivery-options\"></div>\n        </div>\n      ");
    }
  }
  /**
   * @param {string} selector
   *
   * @returns {jQuery|null}
   */


  function getElement(selector) {
    var element = $(selector);
    return element.length ? element : null;
  }
  /**
   * @param {Object} data
   */


  function updateInput(data) {
    var $input = getInput();
    var dataString = JSON.stringify(data);
    $input.val(dataString);
    var $checkoutDeliverStep = $('#checkout-delivery-step');
    var isOnDeliverStep = $checkoutDeliverStep.hasClass('js-current-step') || $checkoutDeliverStep.hasClass('-current');

    if (isOnDeliverStep) {
      $input.trigger('change');
    }
  }
  /**
   * @returns {jQuery|null}
   */


  function getDeliveryOptionsRow() {
    var row = $('.delivery-option input:checked').closest('.delivery-option');
    return row.length ? row : null;
  }
  /**
   * @returns {jQuery}
   */


  function getInput() {
    var $input = $('#mypa-input');

    if (!$input.length) {
      $input = $('<input type="hidden" id="mypa-input" name="myparcel-delivery-options" />');
      var $wrapper = getDeliveryOptionsFormHandle();

      if ($wrapper.length) {
        $wrapper.append($input);
      }
    }

    return $input;
  }
  /**
   * @returns {jQuery|null}
   */


  function getDeliveryOptionsFormHandle() {
    return getElement('#myparcel-form-handle');
  }
  /**
   * @returns {boolean}
   */


  function hasUnRenderedDeliveryOptions() {
    return Boolean(getElement('#myparcel-delivery-options'));
  }
  /**
   * @param {string} carrierId
   */


  function updateConfig(carrierId) {
    var hasCarrierConfig = deliveryOptionsConfigStore.hasOwnProperty(carrierId);

    if (!hasCarrierConfig) {
      $.ajax({
        url: "".concat(window.myparcel_delivery_options_url, "?carrier_id=").concat(carrierId),
        dataType: 'json',
        async: false,
        success: function success(data) {
          deliveryOptionsConfigStore[carrierId] = data;
          window.MyParcelConfig = deliveryOptionsConfigStore[carrierId].data;
          updateDeliveryOptions();
        }
      });
    }

    window.MyParcelConfig = deliveryOptionsConfigStore[carrierId].data;
  }
  /**
   * @param {jQuery} $deliveryOptionsRow
   */


  function initializeMyParcelForm($deliveryOptionsRow) {
    if (!$deliveryOptionsRow || !$deliveryOptionsRow.length || !$deliveryOptionsRow.find('input:checked')) {
      return;
    }

    var carrierId = $deliveryOptionsRow.find('input:checked')[0].value.split(',').join('');
    var $wrapper = $deliveryOptionsRow.next().find('.myparcel-delivery-options-wrapper');

    if (!$wrapper) {
      return;
    }

    createOrMoveDeliveryOptionsForm($wrapper);
    updateConfig(carrierId);
    updateDeliveryOptions();
  }
  /**
   *
   */


  function updateDeliveryOptions() {
    if (hasUnRenderedDeliveryOptions()) {
      document.dispatchEvent(new Event('myparcel_render_delivery_options'));
    } else {
      document.dispatchEvent(new Event('myparcel_update_config'));
    }
  }
  /**
   * Initialize all listeners.
   */


  function start() {
    if (initialized) {
      return;
    }

    initialized = true;
    window.prestashop.on('updatedDeliveryForm', function (event) {
      initializeMyParcelForm(event.deliveryOption);
    });
    initializeMyParcelForm(getDeliveryOptionsRow());
    document.addEventListener('myparcel_updated_delivery_options', function (event) {
      getDeliveryOptionsFormHandle().slideDown();

      if (event.detail) {
        updateInput(event.detail);
      }
    });
  }

  document.addEventListener('DOMContentLoaded', function () {
    if (!document.querySelector('#checkout-delivery-step.js-current-step')) {
      window.prestashop.on('changedCheckoutStep', start);
      return;
    }

    start();
  }); // Hack to keep prestashop from hiding the checkout when icons inside the delivery options are clicked.

  window.prestashop.on('changedCheckoutStep', function (values) {
    var $currentTarget = $(values.event.currentTarget);

    if (!$currentTarget.hasClass('-current')) {
      var $activeStep = $('.checkout-step.-current');

      if (!$activeStep.length) {
        $currentTarget.addClass('-current');
        $currentTarget.addClass('js-current-step');
      }
    }
  });
})();

},{}]},{},[1]);
